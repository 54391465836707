import React from 'react'
import Master from '../components/Layouts/Master'

function About() {
    return (
        <Master>
            <div>
                {/* Page Title */}
                <section className="page-title" style={{ backgroundImage: 'url(images/1.jpg)' }}>
                    <div className="auto-container">
                        <h2>About Silverline</h2>
                        <ul className="bread-crumb clearfix">
                            <li><a href="index.html">Home</a></li>
                            <li>About us</li>
                        </ul>
                    </div>

                </section>
                {/* End Page Title */}




                {/* About Section Two / Style Two */}
                <section className="about-section-two style-two">
                    <div className="auto-container">
                        <div className="row clearfix">
                            {/* Image Column */}
                            <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="image">
                                        <img src="images/resource/about.jpg" alt />
                                        <div className="experience-box" style={{ backgroundImage: 'url(images/background/19.png)' }}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content Column */}
                            <div className="content-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    {/* Sec Title */}
                                    <div className="sec-title">
                                        <div className="title">About Us</div>
                                        <h2>SILVERLINE RESOURCE <br />COMPANY LIMITED </h2>
                                        <div className="text">is a limited liability company registered legally under the laws of Ghana on the 1st day of
                                            June 2020. The business operation and office is located at Westland on the
                                            HNo.P51 Sunday Street, West Legon. It is a resource company with a
                                            focus in general construction engineering works, civil engineering works,
                                            Logistics and Procurement services</div>
                                        <div className="text">Our operations are managed by professionally trained and self-motivated
                                            individuals in the scope of building technology and project management,
                                            Consultancy Services and a broader experience in civil and plumbing
                                            engineering works</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* End About Section Two */}
                <section className="counter-section style-three" style={{ backgroundImage: 'url(images/background/21.png)' }}>
                    <div className="auto-container">
                        <div className="inner-container">
                            <div className="row clearfix counter">
                                <p style={{ color: 'white' }} >Silverline Resource Company Limited has envisioned some business prospects for itself in the foreseeable future. The vision gazes at the future of the business and what it wants to achieve in the industry
                                    and the environment within which it serves clients through business and services provided within the Ghanaian economy.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="fluid-section-one style-two">
                    <div className="color-layer" />
                    <div className="outer-container clearfix">
                        {/* Image Column */}
                        <div
                            className="image-column"
                            style={{
                                backgroundImage: "url(images/workers2.webp)",
                            }}
                        >

                            <figure className="image-box">
                                <img src="images/worker.webp" alt />
                            </figure>
                        </div>
                        {/* Content Column */}
                        <div
                            className="content-column"
                            style={{ backgroundImage: "url(images/background/11.png)", backgroundSize: 'cover' }}
                        >
                            <div className="inner-column">
                                {/* Sec Title */}
                                <div className="sec-title light">
                                    <div className="title">Our Vision</div>
                                    <h2>Our Vision

                                    </h2>
                                    <div className="text">
                                        <ul>
                                            <li> <p>To create a very vibrant and peaceful environment for investors to help attract massive investment into the real estate and construction sector to build our country Ghana and the African continent...</p></li>
                                            <li><p>To be the fastest growing construction firm and project consultants in the country and the African sub-region building landmarks, major architectural designs and providing professional services with
                                                the highest standards in our operating economies whiles shareholders wealth increase shall a focus.</p></li>
                                            <li><p>To be a major player and influencing of decision making and help formulate policies and projects that will increase the growth and development of the construction, Logistics and Procurement sector
                                                in the country</p></li>
                                            <li><p>To be a leading member of Civil and Structural Engineering firms in Ghana, working hard to increase productivity and quality services in its operating Sectors. </p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="button-box">
                                    <a
                                        href="contact.html"
                                        className="theme-btn btn-style-one clearfix"
                                    >
                                        <span className="btn-wrap">
                                            <span className="text-one">Need any help</span>
                                            <span className="text-two">Need any help</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




            </div>


        </Master>
    )
}

export default About
