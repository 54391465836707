import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RouterIndex from './Routes/RouterIndex'

function App() {
  return (
    <BrowserRouter>
        
    <RouterIndex/>
 
  </BrowserRouter>
  )
}

export default App
