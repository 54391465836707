import React from 'react'
import Master from '../components/Layouts/Master'

function Services() {
    return (
        <Master>
            <div>
                <section className="fluid-section-one style-two">
                    <div className="color-layer" />
                    <div className="outer-container clearfix">
                        {/* Image Column */}
                        <div className="image-column" style={{ backgroundImage: 'url(images/resource/video-image-2.jpg)' }}>

                        </div>
                        {/* Content Column */}
                        <div className="content-column" style={{ backgroundImage: 'url(images/background/13.png)' }}>
                            <div className="inner-column">
                                {/* Sec Title */}
                                <div className="sec-title light">
                                    <div className="title">Silverline Resource</div>
                                    <h2>OUR SERVICES</h2>
                                    <div className="text">Silverline Resource Company Limited provides many services and products to its prospective
                                        clients in the Ghanaian market and wishes to extend its businesses to other African countries by
                                        2022. The following are its major businesses it undertakes as a company...</div>
                                </div>
                                <div className="button-box">
                                    <a href="/contact" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">Contact Us</span>
                                            <span className="text-two">Contact Us</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Services Page Section */}
                <section className="services-page-section">
                    <div className="auto-container">
                        <div className="inner-container">
                            <div className="row clearfix">
                                {/* Service Block / Style Two */}
                                <div className="service-block style-two col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon flaticon-construction" />
                                        <h4><a href="roof-renovation.html">Construction</a></h4>
                                        <div className="text">                        Experienced in building construction works and renovation works of all standard
                                        </div>
                                    </div>
                                </div>
                                {/* Service Block / Style Two */}
                                <div className="service-block style-two col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon flaticon-construction" />
                                        <h4><a href="roof-renovation.html">Civil Engineering</a></h4>
                                        <div className="text"> The company with its experienced staff has also undertaken many civil engineering works and is capable of undertaking any civil engineering project...</div>

                                    </div>
                                </div>
                                {/* Service Block / Style Two */}
                                <div className="service-block style-two col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon flaticon-global" />
                                        <h4><a href="roof-renovation.html">Logistics Procurement</a></h4>
                                        <div className="text">                        Procurement works and logistics supply within its operations.
                                        </div>

                                    </div>
                                </div>
                                {/* Service Block / Style Two */}
                                <div className="service-block style-two col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon flaticon-snowing" />
                                        <h4><a href="roof-renovation.html">Wood Works</a></h4>
                                        <div className="text">We provide professional, quality and good finishing wood work services of all types to our clients</div>

                                    </div>
                                </div>
                                {/* Service Block / Style Two */}
                                <div className="service-block style-two col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon flaticon-roof-2" />
                                        <h4><a href="roof-renovation.html">General Supplies</a></h4>
                                        <div className="text">We do also undertake many supply contract with organizations ranging from office stationary to equipments, computers and vehicles. We supply to meet specification</div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/* End Services Page Section */}
            </div>

        </Master>
    )
}

export default Services
