import React from 'react'

function Navbar() {
  return (
    <nav className="main-menu show navbar-expand-md">
                <div className="navbar-header">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li className="dropdown"><a href="/">Home</a>
                      
                    </li>
                    <li className="dropdown"><a href="/about">About</a>
                     
                    </li>
                    <li className="dropdown"><a href="/services">Services</a>
                     
                    </li>
                    <li className="dropdown"><a href="/contact">contact</a>
                     
                    </li>
                  
                   
                  </ul>
                </div>
              </nav>
  )
}

export default Navbar