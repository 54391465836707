import React from 'react'

function Sidebar() {
    return (
        <div>
            {/* Sidebar Cart Item */}
            <div className="xs-sidebar-group info-group">
                <div className="xs-overlay xs-bg-black" />
                <div className="xs-sidebar-widget">
                    <div className="sidebar-widget-container">
                        <div className="widget-heading">
                            <a href="#" className="close-side-widget">
                                X
                            </a>
                        </div>
                        <div className="sidebar-textwidget">
                            {/* Sidebar Info Content */}
                            <div className="sidebar-info-contents">
                                <div className="content-inner">
                                    <div className="logo">
                                        <a href="index.html">
                                            <img src="images/logo.png" alt title= "Silverline Resource Ltd"/>
                                        </a>
                                    </div>
                                    <div className="content-box">
                                        <h5>About Us</h5>
                                        <p className="text">
                                            SILVERLINE RESOURCE
                                            COMPANY LIMITED is a limited liability company registered legally under the laws of Ghana on the 1st day of June 2020. The business operation and office is located at Westland on the HNo.P51 Sunday Street, West Legon. It is a resource company with a focus in general construction engineering works, civil engineering works, Logistics and Procurement services
                                        </p>

                                    </div>
                                    <div className="contact-info">
                                        <h5>Contact Info</h5>
                                        <ul className="list-style-one">
                                            <li>
                                                <span className="icon fa fa-location-arrow" />
                                                H/No. P51 Sunday Street, West Legon
                                            </li>
                                            <li>
                                                <span className="icon fa fa-phone" />
                                                +233 20 923 3720
                                            </li>
                                            <li>
                                                <span className="icon fa fa-envelope" />
                                                info@Silverlineresource.com
                                            </li>

                                        </ul>
                                    </div>
                                    {/* Social Box */}
                                    <ul className="social-box">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/"
                                                className="fa fa-facebook-f"
                                            />
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.twitter.com/"
                                                className="fa fa-twitter"
                                            />
                                        </li>
                                        <li>
                                            <a
                                                href="https://dribbble.com/"
                                                className="fa fa-dribbble"
                                            />
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/"
                                                className="fa fa-linkedin"
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END sidebar widget item */}
        </div>
    )
}

export default Sidebar
