import React from 'react'
import Master from '../components/Layouts/Master'

function Contact() {
    return (
        <Master>

            <div>
                {/* Page Title */}
                <section className="page-title" style={{ backgroundImage: 'url(images/1.jpg)' }}>
                    <div className="auto-container">
                        <h2>contact</h2>
                        <ul className="bread-crumb clearfix">
                            <li><a href="/">Home</a></li>
                            <li>contact</li>
                        </ul>
                    </div>
                    {/* Scroll To Down */}
                    <div className="scroll-to-down scroll-to-target" data-target=".services-page-section"><span className="flaticon-down-arrow-1" /></div>
                </section>
                {/* End Page Title */}

                {/* Contact Info Section */}
                <section className="contact-info-section-two">
                    <div className="auto-container">
                        <div className="row clearfix">
                            {/* Info Block */}
                            <div className="info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner-box">
                                    <div className="icon-outer">
                                        <div className="icon flaticon-map" />
                                    </div>
                                    <h4>address </h4>
                                    <div className="text">H/No. P51 Sunday Street, West Legon</div>
                                </div>
                            </div>
                            {/* Info Block */}
                            <div className="info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner-box">
                                    <div className="icon-outer">
                                        <div className="icon flaticon-telephone" />
                                    </div>
                                    <h4>Phone Number</h4>
                                    <div className="text">
                                        <a href="tel:+233 20 923 3720">+233 20 923 3720</a><br />
                                        <a href="tel:+233 50 479 7836">+233 50 479 7836</a>
                                    </div>
                                </div>
                            </div>
                            {/* Info Block */}
                            <div className="info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner-box">
                                    <div className="icon-outer">
                                        <div className="icon flaticon-email-1" />
                                    </div>
                                    <h4>Mail Adress</h4>
                                    <div className="text">
                                        <a href="mailto:info@silverlineresource.com">info@silverlineresource.com</a><br />

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Form Box */}
                        <div className="form-box">
                            <h3>Need any kind of roofing help?? Send us a Massage</h3>
                            {/* Contact Form */}
                            <div className="contact-form">
                                <form method="post" action="sendemail.php" id="contact-form">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="username" placeholder="Enter your name*" required />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="email" name="email" placeholder="Enter your mail .." required />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="phone" placeholder="Enter your Number*" required />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="subject" placeholder="Subject*" required />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <textarea className name="message" placeholder="Type your Comment" defaultValue={""} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group clearfix">
                                            <div className="pull-left">
                                                <button className="theme-btn btn-style-two">
                                                    <span className="btn-wrap">
                                                        <span className="text-one">Send Message</span>
                                                        <span className="text-two">Send Message</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="pull-right">
                                                <div className="save">Save my name, email, and website in this browser for the next time I comment.</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* End Contact Form */}
                        </div>
                    </div>
                </section>
                {/* End Contact Info Section */}
                {/* Map Column */}
                <section className="map-section">
                    {/* Map Outer */}
                    <div className="map-outer">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.3390466704946!2d-0.21499398523359206!3d5.664013795894354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9d49ad279981%3A0xd856c8a04a5ea8d0!2sSilverline%20Resource%20Limited!5e0!3m2!1sen!2sgh!4v1679118006293!5m2!1sen!2sgh" allowFullScreen />
                    </div>
                </section>
                {/* End Map Column */}
                {/* Newsletter Section */}
                <section className="newsletter-section" style={{ backgroundImage: 'url(images/background/4.jpg)' }}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                <h3>Subscribe Our Newslatter</h3>
                            </div>
                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                {/* Email Box */}
                                <div className="email-box">
                                    <form method="post" action="/">
                                        <div className="form-group">
                                            <input type="email" name="search-field" defaultValue placeholder="Enter your mail" required />
                                            <button type="submit" className="theme-btn submit-btn"><i className="flaticon-send" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Newsletter Section */}
            </div>

        </Master>
    )
}

export default Contact
