import React from 'react'
import Navbar from './Navbar'

function Header() {
  return (
    <header className="main-header header-style-three">
      {/* Header Lower */}
      <div className="header-lower">
        <div className="auto-container">
          <div className="inner-container clearfix">
            <div className="pull-left logo-box">
              <div className="logo"><a href="/"><img src="images/logo.png" alt title="Silverline Resource" /></a></div>
            </div>
            <div className="nav-outer clearfix">
              {/* Mobile Navigation Toggler */}
              <div className="mobile-nav-toggler"><span className="icon flaticon-menu" /></div>
              {/* Main Menu */}
              <Navbar />
              {/* Main Menu End*/}
              {/* Outer Box */}
              <div className="outer-box clearfix">
                {/* Search Box */}

                {/* Nav Btn */}
                <div className="nav-btn navSidebar-button"><span className="icon flaticon-menu" /></div>
              </div>
              {/* End Outer Box */}
            </div>
          </div>
        </div>
      </div>
      {/* End Header Lower */}
      {/* Sticky Header  */}
      <div className="sticky-header">
        <div className="auto-container clearfix">
          {/* Logo */}
          <div className="logo pull-left">
            <a href="index.html" title><img style={{ width: '200px' }} src="images/logo.png" alt title /></a>
          </div>
          {/*Right Col*/}
          <div className="pull-right">
            {/* Main Menu */}
            <nav className="main-menu">
              {/*Keep This Empty / Menu will come through Javascript*/}
            </nav>
            {/* Main Menu End*/}
            {/* Mobile Navigation Toggler */}
            <div className="mobile-nav-toggler"><span className="icon flaticon-menu" /></div>
          </div>
        </div>
      </div>
      {/* End Sticky Menu */}
      {/* Mobile Menu  */}
      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn"><span className="icon flaticon-multiply" /></div>
        <nav className="menu-box">
          <div className="nav-logo"><a href="index.html"><img src="images/logo.png" alt="Silverline" title /></a></div>
          {/* Search */}
          <div className="search-box">
            <form method="post" action="contact.html">
              <div className="form-group">
                <input type="search" name="search-field" defaultValue placeholder="SEARCH HERE" required />
                <button type="submit"><span className="icon flaticon-search-1" /></button>
              </div>
            </form>
          </div>
          <div className="menu-outer">{/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}</div>
        </nav>
      </div>
      {/* End Mobile Menu */}
    </header>
  )
}

export default Header