import React from "react";
import Master from "../components/Layouts/Master";

function Home() {
  return (
    <Master>
      <div>
        <div>

          {/* Main Section Two */}
          <section
            className="main-slider-two"
            style={{ backgroundImage: "url(images/bg.jpg)", height: '100vh', backgroundSize: 'cover' }}
          >
            <div className="main-slider-carousel owl-carousel owl-theme">
              {/* Slide One */}
              <div className="slide">
                <div className="auto-container">
                  {/* Content Boxed */}
                  <div className="content-boxed" style={{ height: '100vh' }}>
                    <div className="inner-box">
                      <div className="title"> Silverline Resource </div>
                      <h1>CONSTRUCTION</h1>
                      <div className="text">
                        Experienced in building construction works and renovation works of all standard
                      </div>
                      <div className="btns-box ">
                        <a href="/contact" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">Contact Us</span>
                            <span className="text-two">Contact Us</span>
                          </span>
                        </a>

                      </div>

                    </div>
                  </div>
                  {/* Side Image */}
                  <div className="side-image">

                    <img src="images/main-slider/slider1.jpg" alt />
                  </div>
                </div>
              </div>
              {/* End Slide One */}
              {/* Slide Two */}
              <div className="slide">
                <div className="auto-container">
                  {/* Content Boxed */}
                  <div className="content-boxed">
                    <div className="inner-box">
                      <div className="title">Silverline Resource...</div>
                      <h1>LOGISTICS / PROCUREMENT</h1>
                      <div className="text">
                        Procurement works and logistics supply within its operations.
                      </div>
                      <div className="btns-box">
                        <a href="/contact" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">Contact Us</span>
                            <span className="text-two">Contact Us</span>
                          </span>
                        </a>


                      </div>

                    </div>
                  </div>
                  {/* Side Image */}
                  <div className="side-image">
                    {/* Plus Box */}

                    <img src="images/main-slider/slider2.jpg" alt />
                  </div>
                </div>
              </div>
              {/* End Slide Two */}
              {/* Slide Three */}
              <div className="slide">
                <div className="auto-container">
                  {/* Content Boxed */}
                  <div className="content-boxed">
                    <div className="inner-box">
                      <div className="title">Silverline Resource</div>
                      <h1>CIVIL ENGINEERING</h1>
                      <div className="text">
                        The company with its experienced staff has also undertaken many civil engineering works and is capable of undertaking any civil engineering project...
                      </div>

                      <div className="btns-box">
                        <a href="/contact" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">Contact Us</span>
                            <span className="text-two">Contact Us</span>
                          </span>
                        </a>

                      </div>

                    </div>
                  </div>
                  {/* Side Image */}
                  <div className="side-image">
                    {/* Plus Box */}

                    <img src="images/main-slider/image-2.png" alt />
                  </div>
                </div>
              </div>
              {/* End Slide Three */}
            </div>
          </section>
          {/* End Main Slider Two */}
          {/* Services Section Three */}
          <section className="services-section-three">
            <div className="outer-container">
              <div className="services-carousel owl-carousel owl-theme">
                {/* Service Block Three */}
                <div className="service-block-three">
                  <div
                    className="inner-box d-flex justify-content-between align-items-center"
                    style={{ backgroundImage: "url(images/background/11.png)" }}
                  >
                    {/* Content Column */}
                    <div className="content-column">
                      <div className="inner-column">
                        <div className="icon flaticon-brickwall" />
                        <h4>
                          <a href="roof-renovation.html">CONSTRUCTION</a>
                        </h4>
                        <div className="text">
                          Experienced in building construction works and renovation works of all standard

                        </div>
                      </div>
                    </div>
                    {/* Image Column */}
                    <div className="image-column">
                      <div className="inner-column">
                        <div className="image">
                          <img src="images/main-slider/slider1.jpg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Service Block Three */}
                <div className="service-block-three">
                  <div
                    className="inner-box d-flex justify-content-between align-items-center"
                    style={{ backgroundImage: "url(images/background/11.png)" }}
                  >
                    {/* Content Column */}
                    <div className="content-column">
                      <div className="inner-column">
                        <div className="icon flaticon-home-repair" />
                        <h4>
                          <a href="roof-renovation.html">LOGISTICS / PROCUREMENT</a>
                        </h4>
                        <div className="text">
                          Procurement works and logistics supply within its operations.
                        </div>
                      </div>
                    </div>
                    {/* Image Column */}
                    <div className="image-column">
                      <div className="inner-column">
                        <div className="image">
                          <img src="images/main-slider/slider2.jpg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Service Block Three */}
                <div className="service-block-three">
                  <div
                    className="inner-box d-flex justify-content-between align-items-center"
                    style={{ backgroundImage: "url(images/background/11.png)" }}
                  >
                    {/* Content Column */}
                    <div className="content-column">
                      <div className="inner-column">
                        <div className="icon flaticon-brickwall" />
                        <h4>
                          <a href="roof-renovation.html">CIVIL ENGINEERING</a>
                        </h4>
                        <div className="text">
                          The company with its experienced staff has also undertaken many civil engineering works and is capable of undertaking any civil engineering project..
                        </div>
                      </div>
                    </div>
                    {/* Image Column */}
                    <div className="image-column">
                      <div className="inner-column">
                        <div className="image">
                          <img src="images/main-slider/slider1.jpg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Service Block Three */}
                <div className="service-block-three">
                  <div
                    className="inner-box d-flex justify-content-between align-items-center"
                    style={{ backgroundImage: "url(images/background/11.png)" }}
                  >
                    {/* Content Column */}
                    <div className="content-column">
                      <div className="inner-column">
                        <div className="icon flaticon-home-repair" />
                        <h4>
                          <a href="roof-renovation.html">Wood Works</a>
                        </h4>
                        <div className="text">
                          We provide professional quality and good finishing wood work services of all types to our clients
                        </div>
                      </div>
                    </div>
                    {/* Image Column */}
                    <div className="image-column">
                      <div className="inner-column">
                        <div className="image">
                          <img src="images/main-slider/slider3.jpg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Service Block Three */}

              </div>
            </div>
          </section>
          {/* End Services Section Three */}

          {/* Fluid Section One / Style Two */}
          <section className="fluid-section-one style-two">
            <div className="color-layer" />
            <div className="outer-container clearfix">
              {/* Image Column */}
              <div
                className="image-column"
                style={{
                  backgroundImage: "url(images/worker.webp)",
                }}
              >

                <figure className="image-box">
                  <img src="images/worker.webp" alt />
                </figure>
              </div>
              {/* Content Column */}
              <div
                className="content-column"
                style={{ backgroundImage: "url(images/background/11.png)", backgroundSize: 'cover' }}
              >
                <div className="inner-column">
                  {/* Sec Title */}
                  <div className="sec-title light">
                    <div className="title">Silverline Resource Company Limited</div>
                    <h2>AT Silverline our BRAND is
                      defined by our customers
                      experieince.</h2>
                    <div className="text">
                      Our operations are managed by professionally trained and self-motivated
                      individuals in the scope of building technology and project management,
                      Consultancy Services and a broader experience in civil and plumbing
                      engineering works..
                    </div>
                  </div>
                  <div className="button-box">
                    <a
                      href="contact.html"
                      className="theme-btn btn-style-one clearfix"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Need any help</span>
                        <span className="text-two">Need any help</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Fluid Section One / Style Two */}




        </div>

      </div>
    </Master>
  );
}

export default Home;
