import React from 'react'

function Footer() {
  return (
    <footer className="main-footer">
      <div className="auto-container">
        {/* Widgets Section */}
        <div className="widgets-section">
          <div className="row clearfix">
            {/* Column */}
            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/* Footer Column */}
                <div className="footer-column col-lg-7 col-md-6 col-sm-12">
                  <div className="footer-widget career-widget">
                    <div className="title">CONTACT US</div>

                    <a href="tel:+233 20 923 3720" className="email">+233 20 923 3720</a>
                    <br />
                    <a className="email" href="mailto:info@silverlineresource.com">info@silverlineresource.com</a>
                  </div>
                </div>
                {/* Footer Column */}
                <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h6>Our Services</h6>
                    <ul className="links">
                      <li><a href="/services">Construction</a></li>
                      <li><a href="/services">Civil Engineering</a></li>
                      <li><a href="/services">Logistics</a></li>
                      <li><a href="/services">Wood Works</a></li>
                      <li><a href="/services">General Supplies</a></li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Column */}
            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/* Footer Column */}
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h6>Useful links</h6>
                    <ul className="links">
                      <li><a href="#">Contact us</a></li>
                      <li><a href="#">Help &amp; About us</a></li>

                      <li><a href="#">Services</a></li>



                    </ul>
                  </div>
                </div>
                {/* Footer Column */}
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h6>Office locations </h6>
                    <ul className="time-schedule">
                      <li>H/No. P51 Sunday Street, West Legon</li>


                    </ul>
                    {/* Social Box */}
                    <ul className="social-icon-one">
                      <li><a href="https://www.facebook.com/" className="fa fa-facebook-f" /></li>
                      <li><a href="https://www.twitter.com/" className="fa fa-twitter" /></li>
                      <li><a href="https://behance.com/" className="fa fa-linkedin" /></li>
                      <li><a href="https://www.youtube.com/" className="fa fa-youtube-play" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="row clearfix">
            {/* Copyright Column */}
            <div className="copyright-column col-lg-6 col-md-6 col-sm-12">
              <div className="copyright">© 2023 Silverline Resource  </div>
            </div>
            {/* Nav Column */}

          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer